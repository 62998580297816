/***
Styling for widgets
***/

/*Entry meta*/

.entry-meta{line-height:1;}
.entry-meta.meta-2 .author-img img{width:40px;height:40px;border-radius:50%;}
.entry-meta.meta-2 .author-name{text-transform:uppercase;font-weight:600;font-size:14px;display:inline-block;margin-top:5px;}
.entry-meta .author-add{font-size:12px;}
.entry-meta.meta-1 span{margin-right:10px;}
.entry-meta.meta-1 span i{margin-right:3px;}
.entry-meta.meta-0 span{padding:4px 10px 4px 19px;font-size:11px;letter-spacing:0.8px;font-weight:bold;text-transform:uppercase;border-radius:30px;position:relative;}
.entry-meta.meta-0 span::before{content:"";width:6px;height:6px;background:none;margin-right:3px;border-radius:5px;display:inline-block;position:absolute;top:50%;left:8px;margin-top:-3px;border:1px solid #fff;}
span.has-dot{position:relative;padding-left:10px;}
span.has-dot::before{content:"";width:2px;height:2px;background:#999;border-radius:50%;position:absolute;top:50%;margin-top:-1px;display:block;left:-3px;}
.widget-header .widget-title{position:relative;z-index:2;}

/*SLICK SLIDER*/

/*featured-slider-1*/

.slick-slide img{max-width:100%;height:auto;}
.featured-slider-1{background:#fff;box-shadow:0px 0px 40px 0px rgba(0,0,0,0.05);}
.slider-caption{padding:30px;}
.arrow-cover{position:absolute;margin-top:-50px;right:30px;font-size:2px;}
button.slick-arrow{background:none;border:0;position:relative;padding:0;margin:0 5px;}
button.slick-arrow [class^="flaticon-"]::before{font-size:15px;}

/*featured-slider-2*/
.featured-slider-2-items .post-thumb .thumb-overlay{min-height:600px;}
.featured-slider-2 .post-content-overlay{position:absolute;transition-duration:0.2s;width:100%;top:200px;z-index:500;}
.featured-slider-2 .post-content-overlay .post-title{max-width:50%;}
.featured-slider-2-nav-cover{position:absolute;bottom:0;right:0;width:50%;}
.featured-slider-2-nav .slick-current img{border:5px solid;}
.featured-slider-2 .arrow-cover{position:absolute;left:0;z-index:300;}
.featured-slider-2 .arrow-cover i{color:#fff;}

/*widgets-post-carausel-1*/
.editor-picked{padding:30px;}
a.read-more{font-size:12px;border-radius:3px;border:1px solid;padding:6px 8px;}
a.read-more:hover{color:#fff;}
.post-carausel-1-items{padding:30px;}
.post-carausel-1-items h6.post-title{font-size:14px;}
.post-carausel-1-items .entry-meta{font-size:11px;}
.post-carausel-1-arrow{float:right;}
.post-carausel-1 .editor-picked-left{border-right:1px solid #c8e5ea;box-shadow:0 0px 15px rgba(0,0,0,0.15);}

/*widgets-post-carausel-2*/
.post-carausel-2 .img-hover-slide{min-height:310px;}
.slick-dots{display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;margin:0;padding:0;list-style-type:none;position:absolute;top:-30px;right:15px;}
.slick-dots li{margin:0 2px;}
.slick-dots button{display:block;height:8px;width:8px;border:2px solid #B2B2B2;background:transparent;text-indent:-9999px;padding:0;border-radius:6px;}
.slick-dots li.slick-active button{width:20px;}

/*widgets-post-carausel-3*/
.post-carausel-3 .img-hover-slide{min-height:450px;}
.post-carausel-3 .thumb-overlay::before{border-radius:0;}

/*POST MODULES*/

/*Post module 1*/

.post-module-1 .post-content-overlay{position:absolute;bottom:15px;left:15px;transition-duration:0.2s;padding-right:15px;}
.post-module-1 .post-thumb:hover .post-content-overlay{bottom:20px;transition-duration:0.2s;}
.post-module-1 .list-post .post-thumb{width:130px;height:100px;}

/*post-module-4*/
.post-module-4 .img-hover-slide{height:570px;}
.post-module-1.post-module-5 .list-post .post-thumb{width:80px;height:80px;}

/*Post aside 1*/

/*news-flash*/
.news-flash-cover{text-align:right;}
.news-flash-cover h6{line-height:1;margin-bottom:0;}
.news-flash-cover ul{right:0;text-align:right;}

/*Categories*/
.widget_categories li.cat-item,
.widget_archive li,
.widget_pages li,
.widget_recent_comments li,
.widget_nav_menu li{text-align:right;border-bottom:1px dotted rgba(0,0,0,0.15);display:table;width:100%;font-size:15px;padding:5px 0;}
.widget_categories li.cat-item:last-child{border:none;}
.widget_categories li.cat-item a,
.widget_archive li a,
.widget_pages li a{text-align:left;float:left;padding:0;}

/*Tab*/
nav.tab-nav{position:relative;top:0;background:none;border-radius:120px;padding:0 11px;border:0 solid;float:right;text-transform:uppercase;font-size:12px;}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover{border:none;}
nav.tab-nav .nav-tabs{border:none;}
.tab-nav.style-1 .nav-link.active,
.tab-nav.style-1 .nav-link:hover{color:#fff;}
.tab-nav.style-1 .nav-link{display:block;padding:6px 13px;border-radius:30px;line-height:1;margin:0 3px;}

/*Social network widget*/
.follow-us a{display:block;padding:12px 13px;color:#fff;width:50%;border-radius:5px;font-size:15px;overflow:hidden;height:46px;}
.follow-us a .social-count{font-weight:bold;}
.follow-us i.v-align-space{border-right:1px solid rgba(255,255,255,0.5);padding-right:8px;line-height:1;}
.follow-us .social-icon{display:inline-block;position:relative;transition:all .5s ease-in-out;-webkit-transition:all .5s ease-in-out;-moz-transition:all .5s ease-in-out;-o-transition:all .5s ease-in-out;-ms-transition:all .5s ease-in-out;}
.follow-us a:hover i{-webkit-transform:translateY(-35px)!important;-ms-transform:translateY(-35px)!important;transform:translateY(-35px)!important;}
.follow-us a i{-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);-webkit-transition:.2s cubic-bezier(.65,.23,.31,.88);-o-transition:.2s cubic-bezier(.65,.23,.31,.88);transition:.2s cubic-bezier(.65,.23,.31,.88);display:inline-block;font-size:18px;vertical-align:middle;}
.follow-us a i.nth-2{position:absolute;top:38px;left:0;}

/*Widget About*/
.about-author-img{border-radius:50%;width:52px;height:52px;}

/*Videos*/
.video-area{background-image:url(../imgs/theme/bg-20.png);background-repeat:no-repeat;background-color:#021228;background-position:left center;color:#8b8cb3;}
.video-area .letter-background,
.footer-area .letter-background{color:rgba(255,255,255,0.2);}
.play_btn a{color:#fff;border:2px solid #fff;width:70px;height:70px;display:inline-block;border-radius:50%;text-align:center;line-height:70px;font-size:29px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:9;background:rgba(0,0,0,0.5);}
.play_btn.play_btn_small a{width:40px;height:40px;line-height:40px;font-size:20px;}
.play_btn.play_btn_small a i{margin-right:-3px;}

/*Taber*/
.widget-taber a.nav-link{font-size:14px;padding:4px 15px 0 0;float:left;position:relative;}
.widget-taber a.nav-link.active{font-size:18px;padding-top:0;padding-left:15px;}
.widget-taber a.nav-link.active::before{content:"";width:8px;height:8px;display:block;position:absolute;border-radius:50%;left:0;top:50%;margin-top:-4px;animation:shadow-pulse 1s infinite;}
.widget-taber nav.tab-nav{padding:0;}
.widget-taber-content{box-shadow:0px 0px 40px 0px rgba(0,0,0,0.05);}

/*cart icon on nav*/
.nav-shopping-cart{position:absolute;right:0;top:0;}
.nav-shopping-cart a.cart-icon{position:absolute;right:60px;padding:0;}
.nav-shopping-cart a.cart-icon span.cart_count{position:absolute;top:-15px;right:-6px;font-size:11px;background-color:#FF324D;border-radius:50px;height:16px;line-height:16px;color:#fff;min-width:16px;text-align:center;padding:0 5px;display:inline-block;vertical-align:top;margin-left:-5px;margin-right:-5px;}
.dropdown-submenu{position:relative;}
.dropdown-submenu>.dropdown-menu{top:0;left:100%;margin-top:-6px;}
.dropdown-menu.cart_box{width:320px;box-shadow:0 0 10px 3px rgba(0,0,0,0.05);border:0;border-radius:4px;line-height:1;z-index:900;}
.cart_box .cart_list{width:100%;padding:0!important;max-height:242px;overflow-y:auto;}
.cart_list li{display:inline-block;width:100%;border-bottom:1px solid #e6e6e6;padding:15px;line-height:1;}
.cart_list a{vertical-align:top;padding:0!important;text-transform:capitalize;font-weight:600;line-height:2;}
.item_remove{float:right;margin-left:5px;color:#333!important;}
.cart_list img{border:1px solid #eee;background-color:#ffffff;float:left;margin-right:10px;max-width:80px;border-radius:5px;}
.cart_quantity{color:#333;display:table;margin-top:5px;font-weight:500;}
.cart_total{color:#333;margin:0;padding:10px 15px;font-weight:600;text-align:right;}
.cart_total strong{float:left;font-weight:600;}
.cart_buttons{margin:0;padding:10px 15px 20px;text-align:center;}
.cart_buttons .view-cart,
.cart_buttons .checkout{padding:15px 17px!important;min-width:unset;margin:0 5px;line-height:1;font-size:12px;font-weight:bold;color:#fff!important;}

/*BORDER RADIUS DECLARE*/
.border-radius-2{border-radius:2px;overflow:hidden;}
.border-radius-5{border-radius:5px;overflow:hidden;}
.border-radius-10{border-radius:10px;overflow:hidden;}
.border-radius-15{border-radius:15px;overflow:hidden;}

/*PADDING AND MARGIN DECLARE*/
.mt-5{margin-top:5px!important}
.mt-10{margin-top:10px!important}
.mt-15{margin-top:15px!important}
.mt-20{margin-top:20px!important}
.mt-25{margin-top:25px!important}
.mt-30{margin-top:30px!important}
.mt-35{margin-top:35px!important}
.mt-40{margin-top:40px!important}
.mt-45{margin-top:45px!important}
.mt-50{margin-top:50px!important}
.mt-55{margin-top:55px!important}
.mt-60{margin-top:60px!important}
.mt-65{margin-top:65px!important}
.mt-70{margin-top:70px!important}
.mt-75{margin-top:75px!important}
.mt-80{margin-top:80px!important}
.mt-85{margin-top:85px!important}
.mt-90{margin-top:90px!important}
.mt-95{margin-top:95px!important}
.mt-100{margin-top:100px!important}
.mt-105{margin-top:105px!important}
.mt-110{margin-top:110px!important}
.mt-115{margin-top:115px!important}
.mt-120{margin-top:120px!important}
.mt-125{margin-top:125px!important}
.mt-130{margin-top:130px!important}
.mt-135{margin-top:135px!important}
.mt-140{margin-top:140px!important}
.mt-145{margin-top:145px!important}
.mt-150{margin-top:150px!important}
.mt-155{margin-top:155px!important}
.mt-160{margin-top:160px!important}
.mt-165{margin-top:165px!important}
.mt-170{margin-top:170px!important}
.mt-175{margin-top:175px!important}
.mt-180{margin-top:180px!important}
.mt-185{margin-top:185px!important}
.mt-190{margin-top:190px!important}
.mt-195{margin-top:195px!important}
.mt-200{margin-top:200px!important}
.mb-5{margin-bottom:5px!important}
.mb-10{margin-bottom:10px!important}
.mb-15{margin-bottom:15px!important}
.mb-20{margin-bottom:20px!important}
.mb-25{margin-bottom:25px!important}
.mb-30{margin-bottom:30px!important}
.mb-35{margin-bottom:35px!important}
.mb-40{margin-bottom:40px!important}
.mb-45{margin-bottom:45px!important}
.mb-50{margin-bottom:50px!important}
.mb-55{margin-bottom:55px!important}
.mb-60{margin-bottom:60px!important}
.mb-65{margin-bottom:65px!important}
.mb-70{margin-bottom:70px!important}
.mb-75{margin-bottom:75px!important}
.mb-80{margin-bottom:80px!important}
.mb-85{margin-bottom:85px!important}
.mb-90{margin-bottom:90px!important}
.mb-95{margin-bottom:95px!important}
.mb-100{margin-bottom:100px!important}
.mb-105{margin-bottom:105px!important}
.mb-110{margin-bottom:110px!important}
.mb-115{margin-bottom:115px!important}
.mb-120{margin-bottom:120px!important}
.mb-125{margin-bottom:125px!important}
.mb-130{margin-bottom:130px!important}
.mb-135{margin-bottom:135px!important}
.mb-140{margin-bottom:140px!important}
.mb-145{margin-bottom:145px!important}
.mb-150{margin-bottom:150px!important}
.mb-155{margin-bottom:155px!important}
.mb-160{margin-bottom:160px!important}
.mb-165{margin-bottom:165px!important}
.mb-170{margin-bottom:170px!important}
.mb-175{margin-bottom:175px!important}
.mb-180{margin-bottom:180px!important}
.mb-185{margin-bottom:185px!important}
.mb-190{margin-bottom:190px!important}
.mb-195{margin-bottom:195px!important}
.mb-200{margin-bottom:200px!important}
.ml-5{margin-left:5px!important}
.ml-10{margin-left:10px!important}
.ml-15{margin-left:15px!important}
.ml-20{margin-left:20px!important}
.ml-25{margin-left:25px!important}
.ml-30{margin-left:30px!important}
.ml-35{margin-left:35px!important}
.ml-40{margin-left:40px!important}
.ml-45{margin-left:45px!important}
.ml-50{margin-left:50px!important}
.ml-55{margin-left:55px!important}
.ml-60{margin-left:60px!important}
.ml-65{margin-left:65px!important}
.ml-70{margin-left:70px!important}
.ml-75{margin-left:75px!important}
.ml-80{margin-left:80px!important}
.ml-85{margin-left:85px!important}
.ml-90{margin-left:90px!important}
.ml-95{margin-left:95px!important}
.ml-100{margin-left:100px!important}
.ml-105{margin-left:105px!important}
.ml-110{margin-left:110px!important}
.ml-115{margin-left:115px!important}
.ml-120{margin-left:120px!important}
.ml-125{margin-left:125px!important}
.ml-130{margin-left:130px!important}
.ml-135{margin-left:135px!important}
.ml-140{margin-left:140px!important}
.ml-145{margin-left:145px!important}
.ml-150{margin-left:150px!important}
.ml-155{margin-left:155px!important}
.ml-160{margin-left:160px!important}
.ml-165{margin-left:165px!important}
.ml-170{margin-left:170px!important}
.ml-175{margin-left:175px!important}
.ml-180{margin-left:180px!important}
.ml-185{margin-left:185px!important}
.ml-190{margin-left:190px!important}
.ml-195{margin-left:195px!important}
.ml-200{margin-left:200px!important}
.mr-5{margin-right:5px!important}
.mr-10{margin-right:10px!important}
.mr-15{margin-right:15px!important}
.mr-20{margin-right:20px!important}
.mr-25{margin-right:25px!important}
.mr-30{margin-right:30px!important}
.mr-35{margin-right:35px!important}
.mr-40{margin-right:40px!important}
.mr-45{margin-right:45px!important}
.mr-50{margin-right:50px!important}
.mr-55{margin-right:55px!important}
.mr-60{margin-right:60px!important}
.mr-65{margin-right:65px!important}
.mr-70{margin-right:70px!important}
.mr-75{margin-right:75px!important}
.mr-80{margin-right:80px!important}
.mr-85{margin-right:85px!important}
.mr-90{margin-right:90px!important}
.mr-95{margin-right:95px!important}
.mr-100{margin-right:100px!important}
.mr-105{margin-right:105px!important}
.mr-110{margin-right:110px!important}
.mr-115{margin-right:115px!important}
.mr-120{margin-right:120px!important}
.mr-125{margin-right:125px!important}
.mr-130{margin-right:130px!important}
.mr-135{margin-right:135px!important}
.mr-140{margin-right:140px!important}
.mr-145{margin-right:145px!important}
.mr-150{margin-right:150px!important}
.mr-155{margin-right:155px!important}
.mr-160{margin-right:160px!important}
.mr-165{margin-right:165px!important}
.mr-170{margin-right:170px!important}
.mr-175{margin-right:175px!important}
.mr-180{margin-right:180px!important}
.mr-185{margin-right:185px!important}
.mr-190{margin-right:190px!important}
.mr-195{margin-right:195px!important}
.mr-200{margin-right:200px!important}
.p-10{padding:10px!important}
.p-15{padding:15px!important}
.p-20{padding:20px!important}
.p-25{padding:25px!important}
.p-30{padding:30px!important}
.pt-5{padding-top:5px!important}
.pt-10{padding-top:10px!important}
.pt-15{padding-top:15px!important}
.pt-20{padding-top:20px!important}
.pt-25{padding-top:25px!important}
.pt-30{padding-top:30px!important}
.pt-35{padding-top:35px!important}
.pt-40{padding-top:40px!important}
.pt-45{padding-top:45px!important}
.pt-50{padding-top:50px!important}
.pt-55{padding-top:55px!important}
.pt-60{padding-top:60px!important}
.pt-65{padding-top:65px!important}
.pt-70{padding-top:70px!important}
.pt-75{padding-top:75px!important}
.pt-80{padding-top:80px!important}
.pt-85{padding-top:85px!important}
.pt-90{padding-top:90px!important}
.pt-95{padding-top:95px!important}
.pt-100{padding-top:100px!important}
.pt-105{padding-top:105px!important}
.pt-110{padding-top:110px!important}
.pt-115{padding-top:115px!important}
.pt-120{padding-top:120px!important}
.pt-125{padding-top:125px!important}
.pt-130{padding-top:130px!important}
.pt-135{padding-top:135px!important}
.pt-140{padding-top:140px!important}
.pt-145{padding-top:145px!important}
.pt-150{padding-top:150px!important}
.pt-155{padding-top:155px!important}
.pt-160{padding-top:160px!important}
.pt-165{padding-top:165px!important}
.pt-170{padding-top:170px!important}
.pt-175{padding-top:175px!important}
.pt-180{padding-top:180px!important}
.pt-185{padding-top:185px!important}
.pt-190{padding-top:190px!important}
.pt-195{padding-top:195px!important}
.pt-200{padding-top:200px!important}
.pt-260{padding-top:260px!important}
.pb-5{padding-bottom:5px!important}
.pb-10{padding-bottom:10px!important}
.pb-15{padding-bottom:15px!important}
.pb-20{padding-bottom:20px!important}
.pb-25{padding-bottom:25px!important}
.pb-30{padding-bottom:30px!important}
.pb-35{padding-bottom:35px!important}
.pb-40{padding-bottom:40px!important}
.pb-45{padding-bottom:45px!important}
.pb-50{padding-bottom:50px!important}
.pb-55{padding-bottom:55px!important}
.pb-60{padding-bottom:60px!important}
.pb-65{padding-bottom:65px!important}
.pb-70{padding-bottom:70px!important}
.pb-75{padding-bottom:75px!important}
.pb-80{padding-bottom:80px!important}
.pb-85{padding-bottom:85px!important}
.pb-90{padding-bottom:90px!important}
.pb-95{padding-bottom:95px!important}
.pb-100{padding-bottom:100px!important}
.pb-105{padding-bottom:105px!important}
.pb-110{padding-bottom:110px!important}
.pb-115{padding-bottom:115px!important}
.pb-120{padding-bottom:120px!important}
.pb-125{padding-bottom:125px!important}
.pb-130{padding-bottom:130px!important}
.pb-135{padding-bottom:135px!important}
.pb-140{padding-bottom:140px!important}
.pb-145{padding-bottom:145px!important}
.pb-150{padding-bottom:150px!important}
.pb-155{padding-bottom:155px!important}
.pb-160{padding-bottom:160px!important}
.pb-165{padding-bottom:165px!important}
.pb-170{padding-bottom:170px!important}
.pb-175{padding-bottom:175px!important}
.pb-180{padding-bottom:180px!important}
.pb-185{padding-bottom:185px!important}
.pb-190{padding-bottom:190px!important}
.pb-195{padding-bottom:195px!important}
.pb-200{padding-bottom:200px!important}
.pl-5{padding-left:5px!important}
.pl-10{padding-left:10px!important}
.pl-15{padding-left:15px!important}
.pl-20{padding-left:20px!important}
.pl-25{padding-left:25px!important}
.pl-30{padding-left:30px!important}
.pl-35{padding-left:35px!important}
.pl-40{padding-left:40px!important}
.pl-45{padding-left:45px!important}
.pl-50{padding-left:50px!important}
.pl-55{padding-left:55px!important}
.pl-60{padding-left:60px!important}
.pl-65{padding-left:65px!important}
.pl-70{padding-left:70px!important}
.pl-75{padding-left:75px!important}
.pl-80{padding-left:80px!important}
.pl-85{padding-left:85px!important}
.pl-90{padding-left:90px!important}
.pl-95{padding-left:95px!important}
.pl-100{padding-left:100px!important}
.pl-105{padding-left:105px!important}
.pl-110{padding-left:110px!important}
.pl-115{padding-left:115px!important}
.pl-120{padding-left:120px!important}
.pl-125{padding-left:125px!important}
.pl-130{padding-left:130px!important}
.pl-135{padding-left:135px!important}
.pl-140{padding-left:140px!important}
.pl-145{padding-left:145px!important}
.pl-150{padding-left:150px!important}
.pl-155{padding-left:155px!important}
.pl-160{padding-left:160px!important}
.pl-165{padding-left:165px!important}
.pl-170{padding-left:170px!important}
.pl-175{padding-left:175px!important}
.pl-180{padding-left:180px!important}
.pl-185{padding-left:185px!important}
.pl-190{padding-left:190px!important}
.pl-195{padding-left:195px!important}
.pl-200{padding-left:200px!important}
.pr-5{padding-right:5px!important}
.pr-10{padding-right:10px!important}
.pr-15{padding-right:15px!important}
.pr-20{padding-right:20px!important}
.pr-25{padding-right:25px!important}
.pr-30{padding-right:30px!important}
.pr-35{padding-right:35px!important}
.pr-40{padding-right:40px!important}
.pr-45{padding-right:45px!important}
.pr-50{padding-right:50px!important}
.pr-55{padding-right:55px!important}
.pr-60{padding-right:60px!important}
.pr-65{padding-right:65px!important}
.pr-70{padding-right:70px!important}
.pr-75{padding-right:75px!important}
.pr-80{padding-right:80px!important}
.pr-85{padding-right:85px!important}
.pr-90{padding-right:90px!important}
.pr-95{padding-right:95px!important}
.pr-100{padding-right:100px!important}
.pr-105{padding-right:105px!important}
.pr-110{padding-right:110px!important}
.pr-115{padding-right:115px!important}
.pr-120{padding-right:120px!important}
.pr-125{padding-right:125px!important}
.pr-130{padding-right:130px!important}
.pr-135{padding-right:135px!important}
.pr-140{padding-right:140px!important}
.pr-145{padding-right:145px!important}
.pr-150{padding-right:150px!important}
.pr-155{padding-right:155px!important}
.pr-160{padding-right:160px!important}
.pr-165{padding-right:165px!important}
.pr-170{padding-right:170px!important}
.pr-175{padding-right:175px!important}
.pr-180{padding-right:180px!important}
.pr-185{padding-right:185px!important}
.pr-190{padding-right:190px!important}
.pr-195{padding-right:195px!important}
.pr-200{padding-right:200px!important}
.bt-1{border-top:1px solid;}